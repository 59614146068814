import { TextStyles, colors } from 'fitify-ui'
import {
  biggerPhone,
  phone,
  tabletPortrait,
} from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css, keyframes } from 'styled-components'

interface StyledNewsletterProps {
  $isSuccess?: boolean
  $hasError?: boolean
}

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const StyledNewsletter = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background: ${colors.White};
  border: 1px solid ${colors.Gray200};
  border-radius: 24px;
  padding: 48px;
  gap: 24px;

  ${biggerPhone(css`
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
  `)};
`

export const StyledNewsletterCopy = styled.div`
  width: 100%;
  max-width: 432px;
  ${TextStyles} {
    b {
      color: ${colors.Black};
    }
  }
`

export const StyledNewsletterForm = styled.form<StyledNewsletterProps>`
  width: 100%;
  min-width: 440px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  border-radius: 100px;
  background-color: ${(props) =>
    props.$hasError ? colors.Red50 : colors.Gray200};
  transition: background-color 0.2s ease-in-out;

  ${tabletPortrait(css`
    min-width: 300px;
  `)};

  ${biggerPhone(css`
    min-width: unset;
  `)};
`

export const StyledNewsletterEmailInput = styled.input<StyledNewsletterProps>`
  width: 100%;
  min-height: 56px;
  border-radius: 100px;
  margin-bottom: 0;
  padding: 0 12px 0 24px;
  border: none;
  background: transparent;
  outline-offset: 0;
  outline-style: none;
  outline-width: 3px;
  font-family: 'Graphik LCG Web', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: ${colors.Black};

  display: ${(props) => (props.$isSuccess ? 'none' : 'block')};

  &:disabled {
    opacity: 1;
  }

  ${phone(css`
    font-size: 16px;
  `)};

  &::placeholder {
    color: ${colors.Black};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }
`

export const StyledNewsletterEmailSuccessMessage = styled.span<StyledNewsletterProps>`
  display: ${(props) => (props.$isSuccess ? 'flex' : 'none')};
  align-items: center;
  text-align: start;
  width: 100%;
  min-height: 56px;
  margin-bottom: 0;
  padding: 0 24px;
  border: none;
  border-radius: 100px;
  background-color: ${colors.Green50};
  outline-offset: 0;
  outline-style: none;
  outline-width: 3px;
  font-family: 'Graphik LCG Web', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: ${colors.Black};

  ${phone(css`
    font-size: 16px;
    resize: vertical;
  `)};
`

export const ButtonLoader = styled.div`
  cursor: default;
  width: 20px;
  height: 20px;
  margin: 20px auto;
  margin-right: 20px;
  font-size: 10px;
  background: transparent;
  background: linear-gradient(
    to right,
    ${colors.Gray500} 10%,
    rgba(255 255 255 / 0%) 42%
  );
  border-radius: 50%;
  transform: translateZ(0);
  animation: ${spinner} 1.4s infinite linear;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    background: transparent;
    border-radius: 100% 0 0;
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 75%;
    margin: auto;
    border-radius: 50%;
    content: '';
  }
`

export const StyledNewsletterButton = styled.button<StyledNewsletterProps>`
  cursor: pointer;
  white-space: nowrap;
  max-height: 32px;
  min-height: 32px;
  margin: 12px 12px 12px 0;
  padding: 0 16px;
  border-radius: 100px;
  background-color: ${colors.White};
  font-family: 'Graphik LCG Web', sans-serif;
  font-size: 14px;
  color: ${colors.Black};
  font-weight: 500;
  border: 0;

  ${(props) =>
    props.$isSuccess &&
    css`
      display: none;
    `}
  ${biggerPhone(css`
    cursor: default;

    &:focus {
      outline: none;
    }
  `)}
`

export const StyledNewsletterFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledNewsletterErrorMessage = styled.div`
  position: relative;
  display: inline-flex;
  font-size: 10px;
  line-height: 110%;
  font-weight: 500;
  color: ${colors.Red400};
  bottom: -10px;
  text-transform: uppercase;
`
