/**
 * Regular Expression for Email Validation
 *
 * TEST: https://regex101.com/r/mh80bq/2
 *
 * This email regex is not fully compliant with the RFC 5322 or RFC 3696.
 * https://datatracker.ietf.org/doc/html/rfc5322
 * https://datatracker.ietf.org/doc/html/rfc3696
 *
 */

export const EMAIL_REGEXP =
  /^(?!.*[.]{2})[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/
